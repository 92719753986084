.experience-container{
    margin: 4rem 0;
    position: relative;
}



.arrow-left
.arrow-right {
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4db5ff;
    border-radius: 0.65rem;
    border: 1.5px solid #4db5ff;
    background: #130f2a;
    position: absolute;
    top: 55%;
    z-index: 2;
    cursor: pointer;


}

